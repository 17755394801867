import React from 'react';
import { Avatar } from '@mui/material';

const MUIAvatar = ({ content }) => {
  const { bgcolor, width, height, margin, padding, ariaLabel, text, variant } = content;

  return (
    <Avatar
      sx={{ bgcolor, width, height, margin, padding }}
      aria-label={ariaLabel}
      variant={variant}
    >
      {text}
    </Avatar>
  );
};

export default MUIAvatar;