import React from 'react';
import { FormControlLabel, Radio } from '@mui/material';

const MUIRadio = ({ content, onChange }) => {
  return(
    <FormControlLabel
      label={content.label}
      value={content.value}
      onChange={onChange}
      control={<Radio />}
    />
  );
}

export default MUIRadio;