import React from 'react';
import { Icon } from '@mui/material';

const MUIIcon = ({ content: { icon, fontSize, align, margin, padding, lineHeight, color, borderRadius, boxShadow, border, className, display } }) => {

  const iconStyle = {
    margin,
    padding,
    fontSize: fontSize + ' !important',
    lineHeight,
    color,
    borderRadius,
    boxShadow,
    border,
  };

  const getIconComponent = () => {
    if (icon.startsWith('Md')) {
      const iconName = icon.replace('Md', '').replace(/([A-Z])/g, '_$1').trim().substring(1).toLowerCase();
      const iconClass = icon.includes('Outline') ? 'material-icons-outlined' : 'material-icons';
      return <Icon baseClassName={iconClass} className={className} sx={iconStyle}>{iconName}</Icon>;
    } else if (icon.startsWith('Fa')) {
      const iconName = icon.replace(/([A-Z])/g, '-$1').trim().substring(1).toLowerCase();
      return <Icon baseClassName={`fa ${iconName}`} className={className} sx={iconStyle} />;
    }
    return null;  // Return null if no icon matches the criteria
  };

  return (
    <span style={{ display, textAlign: align }}>
      {getIconComponent()}
    </span>
  );
};

export default MUIIcon;