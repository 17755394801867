import React from 'react';
import { Fade, useScrollTrigger } from "@mui/material"

const FadeInOnScroll = props => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: props.threshold,
    target: props.window ? window() : undefined,
  });

  return (
    <Fade appear={true} direction={props.direction} in={trigger}>
      {props.children}
    </Fade>
  );
};

export default FadeInOnScroll;