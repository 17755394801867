import { Alert, Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const Notifications = ({error, loading}) => {
  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {error && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <Alert severity="error"><h3>{error}</h3></Alert>
        </Backdrop>
      )}
    </>
  );
};

export default Notifications;