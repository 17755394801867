import React from 'react';
import { Button, Icon, IconButton } from '@mui/material';

const MUIButton = ({ content }) => {
  const getIconName = (icon, prefix, separator) => {
    return icon.replace(prefix, '').replace(/([A-Z])/g, `${separator}$1`).toLowerCase().slice(1);
  };

  const getIconComponent = (icon) => {
    if (!icon) return null;

    let iconName, iconClass;
    if (icon.startsWith('Md')) {
      iconName = getIconName(icon, 'Md', '_');
      iconClass = icon.includes('Outline') ? 'material-icons-outlined' : 'material-icons';
    } else if (icon.startsWith('Fa')) {
      iconName = getIconName(icon, 'Fa', '-');
      iconClass = `fa fa-brands ${iconName}`;
    } else {
      return null;
    }

    return <Icon baseClassName={iconClass}>{iconName}</Icon>;
  };

  const commonProps = {
    type: content.submit ? 'submit' : null,
    size: content.size || 'medium',
    href: content.href,
    sx: {
      bgcolor: content.bgColor || '',
      color: content.fontColor || '',
      fontSize: content.fontSize || '',
      border: content.border || '',
      textTransform: content.textTransform || 'none',
      padding: content.padding || '',
      margin: content.margin || '',
      width: content.width || null,
      '&:hover': {
        bgcolor: content.bgColorHover || '',
        color: content.fontColorHover || '',
        border: content.borderHover || '',
      },
    },
    disableFocusRipple: content.disableFocusRipple || false,
    disableRipple: content.disableRipple || false,
  };

  const buttonProps = {
    variant: content.variant || 'contained',
    component: content.comp || null,
    startIcon: getIconComponent(content.startIcon),
    endIcon: getIconComponent(content.endIcon),
    disableElevation: content.disableElevation || false,
    fullWidth: content.fullWidth || false,
  };

  return content.type === 'icon-button' ? (
    <IconButton {...commonProps} aria-label={content.text}>
      {getIconComponent(content.startIcon)}
    </IconButton>
  ) : (
    <Button {...commonProps} {...buttonProps}>
      {content.text}
    </Button>
  );
};

export default MUIButton;