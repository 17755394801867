import React from 'react';
import { motion } from 'framer-motion';

const Animate = ({
  props: {
    transitiontype,
    duration,
    initialOpacity,
    xPosStart,
    xPosFinish,
    yPosStart,
    yPosFinish,
    whileInViewOpacity,
    viewPortAmount,
    viewPortOnce,
  },
  children,
}) => (
  <motion.div
    transition={{ ease: transitiontype, duration }}
    initial={{ opacity: initialOpacity }}
    whileInView={{
      x: [xPosStart, xPosFinish],
      y: [yPosStart, yPosFinish],
      opacity: whileInViewOpacity,
    }}
    viewport={{ amount: viewPortAmount, once: viewPortOnce }}
  >
    {children}
  </motion.div>
);

export default Animate;