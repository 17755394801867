import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Paper } from '@mui/material';

import MUI from './Mui';

const MUIPaper = ({ content }) => (
  <>
    {content.papers.data.map(({ id, attributes }) => {
      const { variant, elevation, square, padding, margin, fontColor, bgColor, borderRadius, content: paperContent } = attributes;

      return (
        <Paper
          key={id}
          variant={variant}
          elevation={elevation}
          square={square}
          sx={{ 
            padding,
            margin,
            color: fontColor || '',
            backgroundColor: bgColor || '',
            borderRadius,
          }}
        >
          {paperContent.map(({ id: muiId, __component, ...muiContent }) => (
            <MUI
              key={uuidv4()}
              type={__component}
              content={muiContent}
            />
          ))}
        </Paper>
      );
    })}
  </>
);

export default MUIPaper;