import React from 'react';
import { InputAdornment, TextField } from '@mui/material';

const MUIInput = ({ content, disabled, onChange, value }) => {

  const getInputStyles = () => ({
    width: content.fullWidth ? '99%' : '49%',
    marginRight: '1%',
    label: {
      color: content.labelFontColor,
      padding: "0.25rem 0.25rem",
      "&.Mui-focused": {
        color: content.fieldFocused,
      }
    },
    input: {
      color: content.inputFontColor,
      padding: "1rem 1.5rem",
    },
    "& .MuiInputBase-root": {
      fieldset: {
        border: content.fieldBorder,
      },
      "& .MuiOutlinedInput-input": {
        backgroundColor: content.fieldBgColor,
      },
      "&.MuiFilledInput-root": {
        backgroundColor: content.fieldBgColor,
        padding: "1rem 0 0",
      },
      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: content.fieldFocused,
      },
      "&.MuiInput-root::before": {
        borderBottom: content.fieldBorder,
      },
      "&.MuiFilledInput-root::before": {
        borderBottom: content.fieldBorder,
      },
      "&.MuiInput-root::after": {
        borderColor: content.fieldFocused,
      },
      "&.MuiFilledInput-root::after": {
        borderColor: content.fieldFocused,
      },
      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: content.fieldHover,
      },
    },
  });

  const commonProps = {
    id: content.name?.replace(/\s/g, '-').toLowerCase(),
    name: content.name || '',
    value: value || content.defaultValue || '',
    onChange,
  };

  const visualProps = {
    label: content.Label || '',
    variant: content.Variant || 'outlined',
    color: content.color || '',
    margin: content.margin || 'dense',
    multiline: Boolean(content.multiline),
    maxRows: content.maxRows || undefined,
    hiddenLabel: Boolean(content.hiddenLabel),
    size: content.size || undefined,
    placeholder: content.placeholder || '',
    helperText: content.helperText || '',
    fullWidth: content.fullWidth || false,
    disabled: Boolean(disabled),
    InputProps: {
      startAdornment: content.startAdornmentContent && (
        <InputAdornment position="start">{content.startAdornmentContent}</InputAdornment>
      ),
      endAdornment: content.endAdornmentContent && (
        <InputAdornment position="end">{content.endAdornmentContent}</InputAdornment>
      ),
    },
    sx: getInputStyles(),
  };

  if(content.hidden) {
    return <input type="hidden" {...commonProps} />;
  }
  return <TextField {...commonProps} {...visualProps} />;
};

export default MUIInput;