import { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const useFetchData = (apiCall, params) => {
  const [data, setData] = useState({
    loading: true,
    data: null,
    error: null,
  });

  const paramsRef = useRef(); // Create a ref to store the previous params

  useEffect(() => {
    // Function to compare previous params with current ones
    const paramsChanged = JSON.stringify(paramsRef.current) !== JSON.stringify(params);
    paramsRef.current = params; // Update the ref with current params

    if (!paramsChanged) return <></> // If params haven't changed, don't run the effect

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DBADDRESS}/api/${apiCall}`, {
            headers: { Authorization: `Bearer ${process.env.REACT_APP_AUTH}` },
            params,
            signal
          }
        );

        setData({
          loading: false,
          data: response.data.data,
          error: null,
        });
      } catch (error) {
        setData({
          loading: false,
          data: null,
          error: error.response ? error.response.data : error.message,
        });
      }
    };



    fetchData();
    return () => controller.abort();
  }, [apiCall, params]); // Adding params to dependency array
  
  if(data.error) return data.error;
  return data;
};

export default useFetchData;