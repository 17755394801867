import React from 'react';
import { Stack } from '@mui/material';

import MUI from './Mui';

const MUIStack = ({ content }) => (
  <>
    {content.stacks.data.map((stack, index) => (
      <Stack variant="outlined" elevation={0} key={`stack-${index}`}>
        {stack.attributes.content.map((mui, muiIndex) => (
          <MUI
            type={mui.__component}
            content={mui}
            key={`mui-${muiIndex}`}  // Assuming the order and count of MUI components in a stack are stable, otherwise consider a more stable key
          />
        ))}
      </Stack>
    ))}
  </>
);

export default MUIStack;