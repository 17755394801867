import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link } from '@mui/material';

import MUI from '../Components/Mui';

const MUILink = ({ content, params }) => {

  return (
    <Link href={content.href} underline={content.underline} target={content.target}>

      {content.icons.map((mui) => {
        return(
          <MUI
            type={'mui.svg-icon'}
            content={mui}
            key={uuidv4()}
          />
        );
      })}

      {content.images.map((mui) => {
        return(
          <MUI
            type={'mui.media'}
            content={mui}
            key={uuidv4()}
          />
        );
      })}

      {content.typographies.map((mui) => {
        return(
          <MUI
            type={'mui.typography'}
            content={mui}
            key={uuidv4()}
          />
        );
      })}

    </Link>
  );
};

export default MUILink;