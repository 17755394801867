import React, { useState, useEffect, useMemo } from 'react';
import Sticky from 'wil-react-sticky';

const Stick = ({ containerSelectorFocus, children }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const offsetTop = useMemo(() => {
    if (screenWidth <= 765) return 0;
    if (screenWidth <= 1000) return 65;
    if (screenWidth <= 1080) return 75;
    if (screenWidth <= 1440) return 100;
    if (screenWidth <= 2160) return 135;
    return 200;
  }, [screenWidth]); // Depend on the screenWidth state

  return (
    <Sticky
      containerSelectorFocus={containerSelectorFocus}
      offsetTop={offsetTop}
      className="sticky"
      stickyEnableRange={[700, Infinity]}
    >
      {children}
    </Sticky>
  );
};

export default Stick;