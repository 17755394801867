import React, { useMemo } from 'react';
import ScrollToTop from 'react-scroll-up';
import useFetchData from '../Hooks/useFetchData';
import Notifications from '../Components/Notifications';

import { Fab, ThemeProvider, Tooltip } from '@mui/material';
import { KeyboardArrowUp } from '@mui/icons-material';

import NavBar from './NavBar';
import './Theme.css';
import styling from './Styling';

const Theme = ({ children }) => {

  const queryParameters = useMemo(() => ({ 'populate': '*'}), []);
  const { loading, data, error } = useFetchData('theme', queryParameters);

  const { logoDark, logoLight, nav_bars, sttColor, sttBgcolor, sttBorder, sttRadius, sttColorHover, sttBgcolorHover, sttBorderHover } = data?.attributes || {};

  if (loading) return <Notifications loading={loading} />;
  if (error) return <Notifications error={`API ERROR: /api/theme - ${error.status} ${error.message} - User may need to enter required theme information into the CMS...`} />;

  return (
    <ThemeProvider theme={styling}>
      {nav_bars?.data?.map((nav, index) => (
        <NavBar
          content={nav}
          logoLight={logoLight?.data?.attributes}
          logoDark={logoDark?.data?.attributes}
          key={nav.id + '_' + index} // Prefer using nav.id if available
        />
      ))}

      {children}

      <ScrollToTop
        showUnder={160}
        style={{
          bottom: 30,
          right: 30,
        }}
      >
        <Tooltip title="Back to Top" placement="top" arrow>
          <Fab 
            sx={{
              color: sttColor || 'white',
              backgroundColor: sttBgcolor || styling.palette.primary.main,
              border: sttBorder || 'none',
              borderRadius: sttRadius || '50%',
              '&:hover': {
                color: sttColorHover || 'white',
                backgroundColor: sttBgcolorHover || 'grey',
                border: sttBorderHover || 'none',
              }
            }}
            aria-label="Back to Top"
          >
            <KeyboardArrowUp />
          </Fab>
        </Tooltip>
      </ScrollToTop>
    </ThemeProvider>
  );
}

export default Theme;