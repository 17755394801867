import React from 'react';
import { Typography } from '@mui/material';

const BASE_API_URL = process.env.REACT_APP_DBADDRESS;

const MUIMedia = ({ content }) => {
  console.log(content);

  const media = content.content.data.attributes;

  const capStyle = {
    textAlign: 'center',
    display: 'block',
    padding: '1rem',
  };

  const mediaStyle = {
    border: content.border || '',
    borderRadius: content.borderRadius || '',
    bgcolor: content.bgcolor || '',
    margin: content.margin || '',
    opacity: content.opacity || '',
    transform: content.transform || '',
    filter: content.transform || '',
    width: '100%',
    height: 'auto',
  };

  const commonStyle = {
    padding: content.padding || '',
    border: content.border || '',
    borderRadius: content.borderRadius || '',
    backgroundColor: content.bgcolor || 'transparent',
    boxShadow: content.boxShadow || '',
    display: content.display || 'grid',
    width: content.width || '',
    height: content.height || '',
  };

  return(
    <div style={commonStyle}>
      <img
        src={`${BASE_API_URL}${media.url}`}
        alt={media.alternativeText}
        className={content.className}
        style={mediaStyle}
      />
      {content.showCaption && (
        <Typography component="span" variant="caption" sx={capStyle} >
          {media.caption}
        </Typography>
      )}
    </div>
  );

};

export default MUIMedia;