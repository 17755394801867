import { createTheme } from '@mui/material';

let styling = createTheme({
  breakpoints: {
    values: {
      xs: false,
      sm: false,
      md: false,
      lg: false,
      xl: false,
      mobile: 0,
      tablet: 744,
      laptop: 1366,
      desktop: 1935,
      qhd: 2300,
      uhd: 3000,
    },
  },
});

styling = createTheme(styling, {
  palette: {
    type: 'light',
    primary: {
      main: '#338674',
      contrastText: 'rgba(255,255,255,0.87)',
      light: '#5fb4a4',
      dark: '#214a3d',
      textDecoration: 'none',
    },
    secondary: {
      main: '#c00e3d',
      contrastText: 'rgba(255,255,255,0.87)',
      light: '#e05773',
      dark: '#760032',
    },
    tertiary: {
      main: '#707070',
      dark: '#1d1d1d',
      light: '#dcdcdc',
    },
    error: {
      main: '#ff1507',
      light: '#ff795b',
      dark: '#da0000',
    },
    neutral: {
      main: '#FFFFFF',
      light: '#FFFFFF',
      dark: '#f0f0f0',
    },
    background: {
      default: '#fafafa',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: "'PT Serif',serif",
    lineHeight: 1.2,
    fontSize: 19.92,
    fontWeight: 400,
    root: {
      fontFamily: "'PT Serif',serif",
    },
    body1: {
      fontFamily: "'PT Serif',serif",
      lineHeight: 1.2,
      fontWeight: 400,
      fontColor: "rgba(0,0,0,0.87)",
      [styling.breakpoints.only("mobile")]: {
        fontSize: "1rem",
      },
      [styling.breakpoints.only("tablet")]: {
        fontSize: "1.25rem",
      },
      [styling.breakpoints.only("laptop")]: {
        fontSize: "1rem",
      },
      [styling.breakpoints.only("desktop")]: {
        fontSize: "1.19rem",
      },
      [styling.breakpoints.only("qhd")]: {
        fontSize: "1.38rem",
      },
      [styling.breakpoints.only("uhd")]: {
        fontSize: "1.58rem",
      }
    },
    body2: {
      fontFamily: "'PT Serif',serif",
      fontWeight: 400,
      [styling.breakpoints.only("mobile")]: {
        fontSize: "1.25rem",
      },
      [styling.breakpoints.only("tablet")]: {
        fontSize: "1.4rem",
      },
      [styling.breakpoints.only("laptop")]: {
        fontSize: "2rem",
      },
      [styling.breakpoints.only("desktop")]: {
        fontSize: "2rem",
      },
      [styling.breakpoints.only("qhd")]: {
        fontSize: "2rem",
      },
      [styling.breakpoints.only("uhd")]: {
        fontSize: "2rem",
      }
    },
    button: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      padding: ".78571429rem 1.5rem",
      [styling.breakpoints.between("mobile","tablet")]: {
        fontSize: "1.05rem",
      },
      [styling.breakpoints.between("tablet","laptop")]: {
        fontSize: "1.25rem",
      },
      [styling.breakpoints.between("laptop","desktop")]: {
        fontSize: "1rem",
      },
      [styling.breakpoints.between("desktop","qhd")]: {
        fontSize: "1.25rem",
      },
      [styling.breakpoints.between("qhd","uhd")]: {
        fontSize: "1.35rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "1.5rem",
      }
    },
    caption: {
      fontFamily: "Poppins",
      lineHeight: 1.2,
      fontWeight: 600,
      fontColor: "rgba(0,0,0,0.87)",
      [styling.breakpoints.only("mobile")]: {
        fontSize: "1rem",
      },
      [styling.breakpoints.only("tablet")]: {
        fontSize: "1.25rem",
      },
      [styling.breakpoints.only("laptop")]: {
        fontSize: "1rem",
      },
      [styling.breakpoints.only("desktop")]: {
        fontSize: "1.19rem",
      },
      [styling.breakpoints.only("qhd")]: {
        fontSize: "1.38rem",
      },
      [styling.breakpoints.only("uhd")]: {
        fontSize: "1.58rem",
      }
    },
    overline: {
      fontFamily: "'PT Serif',serif",
      lineHeight: 1.2,
      fontWeight: 400,
      fontColor: "rgba(0,0,0,0.87)",
      [styling.breakpoints.only("mobile")]: {
        fontSize: "1rem",
      },
      [styling.breakpoints.only("tablet")]: {
        fontSize: "1.25rem",
      },
      [styling.breakpoints.only("laptop")]: {
        fontSize: "1rem",
      },
      [styling.breakpoints.only("desktop")]: {
        fontSize: "1.19rem",
      },
      [styling.breakpoints.only("qhd")]: {
        fontSize: "1.38rem",
      },
      [styling.breakpoints.only("uhd")]: {
        fontSize: "1.58rem",
      }
    },
    h1: {
      fontFamily: "Poppins",
      fontWeight: 400,
      [styling.breakpoints.between("mobile", "tablet")]: {
        fontSize: "2.5rem",
      },
      [styling.breakpoints.between("tablet", "laptop")]: {
        fontSize: "4.5rem",
      },
      [styling.breakpoints.between("laptop", "desktop")]: {
        fontSize: "5.16em",
      },
      [styling.breakpoints.between("desktop", "qhd")]: {
        fontSize: "5.3em",
      },
      [styling.breakpoints.between("qhd", "uhd")]: {
        fontSize: "6rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "8rem",
      }
    },
    h2: {
      fontFamily: "Poppins",
      fontWeight: 700,
      [styling.breakpoints.between("mobile", "tablet")]: {
        fontSize: "2rem",
      },
      [styling.breakpoints.between("tablet", "laptop")]: {
        fontSize: "4rem",
      },
      [styling.breakpoints.between("laptop", "desktop")]: {
        fontSize: "3.5rem",
      },
      [styling.breakpoints.between("desktop", "qhd")]: {
        fontSize: "3.6rem",
      },
      [styling.breakpoints.between("qhd", "uhd")]: {
        fontSize: "4.5rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "5rem",
      }
    },
    h3: {
      fontFamily: "Poppins",
      fontWeight: 700,
      [styling.breakpoints.between("mobile", "tablet")]: {
        fontSize: "1.4rem",
      },
      [styling.breakpoints.between("tablet", "laptop")]: {
        fontSize: "1.7rem",
      },
      [styling.breakpoints.between("laptop", "desktop")]: {
        fontSize: "2.05em",
      },
      [styling.breakpoints.between("desktop", "qhd")]: {
        fontSize: "2.15rem",
      },
      [styling.breakpoints.between("qhd", "uhd")]: {
        fontSize: "2.5rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "2.9rem",
      }
    },
    h4: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      [styling.breakpoints.between("mobile","tablet")]: {
        fontSize: "1.3rem",
      },
      [styling.breakpoints.between("tablet","laptop")]: {
        fontSize: "1.4rem",
      },
      [styling.breakpoints.between("laptop","desktop")]: {
        fontSize: "1.5rem",
      },
      [styling.breakpoints.between("desktop","qhd")]: {
        fontSize: "1.75rem",
      },
      [styling.breakpoints.between("qhd","uhd")]: {
        fontSize: "2rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "2.25rem",
      }
    },
    h5: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      [styling.breakpoints.between("mobile", "tablet")]: {
        fontSize: "1.1rem",
      },
      [styling.breakpoints.between("tablet", "laptop")]: {
        fontSize: "2.2rem",
      },
      [styling.breakpoints.between("laptop", "desktop")]: {
        fontSize: "2.8rem",
      },
      [styling.breakpoints.between("desktop", "qhd")]: {
        fontSize: "2.9rem",
      },
      [styling.breakpoints.between("qhd", "uhd")]: {
        fontSize: "3.4rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "3.9rem",
      }
    },
    h6: {
      margin: 0,
      fontFamily: 'Poppins',
      fontWeight: 500,
      [styling.breakpoints.between("mobile", "tablet")]: {
        fontSize: "1.3rem",
      },
      [styling.breakpoints.between("tablet", "laptop")]: {
        fontSize: "2rem",
      },
      [styling.breakpoints.between("laptop", "desktop")]: {
        fontSize: "2.2rem",
      },
      [styling.breakpoints.between("desktop", "qhd")]: {
        fontSize: "2.2rem",
      },
      [styling.breakpoints.between("qhd", "uhd")]: {
        fontSize: "2.2rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "3.5rem",
      }
    },
    subtitle1: {
      fontFamily: "'PT Serif',serif",
      fontWeight: 500,
      [styling.breakpoints.between("mobile", "tablet")]: {
        fontSize: "0.9rem",
      },
      [styling.breakpoints.between("tablet", "laptop")]: {
        fontSize: "1.75rem",
      },
      [styling.breakpoints.between("laptop", "desktop")]: {
        fontSize: "1.7rem",
      },
      [styling.breakpoints.between("desktop", "qhd")]: {
        fontSize: "1.7rem",
      },
      [styling.breakpoints.between("qhd", "uhd")]: {
        fontSize: "2.1rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "2.85rem",
      }
    },
    subtitle2: {
      fontFamily: "'PT Serif',serif",
      fontWeight: 400,
      [styling.breakpoints.between("mobile","tablet")]: {
        fontSize: "0.55rem",
      },
      [styling.breakpoints.between("tablet","laptop")]: {
        fontSize: "1.1rem",
      },
      [styling.breakpoints.between("laptop","desktop")]: {
        fontSize: "1.2em",
      },
      [styling.breakpoints.between("desktop","qhd")]: {
        fontSize: "1.3em",
      },
      [styling.breakpoints.between("qhd","uhd")]: {
        fontSize: "1.4rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "1.5rem",
      }
    },
    title1: {
      fontFamily: "'Herr Von Muellerhoff', cursive",
      fontWeight: 400,
      textShadow: "4px 4px 6px #000000",
      [styling.breakpoints.between("mobile","tablet")]: {
        fontSize: "4rem",
      },
      [styling.breakpoints.between("tablet","laptop")]: {
        fontSize: "6rem",
      },
      [styling.breakpoints.between("laptop","desktop")]: {
        fontSize: "6rem",
      },
      [styling.breakpoints.between("desktop","qhd")]: {
        fontSize: "6rem",
      },
      [styling.breakpoints.between("qhd","uhd")]: {
        fontSize: "6.5rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "7rem",
      }
    },
    navigation: {
      fontFamily: 'Poppins',
      fontWeight: 400,
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#db2828",
          color: "rgba(255,255,255,0.87)",
          '&:hover': {
            backgroundColor: "#971c1c",
            color: "rgba(255,255,255,0.5)",
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          height: "100%",
          display: "flex",
          borderRadius: "0.5rem",
          flexDirection: "column",
          justifyContent: "space-between",
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          height: "100%",
          position: "relative",
          padding: "1.5rem",
          borderTop: "1px solid rgba(0,0,0,0.15)"
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "#db2828",
          padding: "1.5rem 1rem",
          top: "-1.5rem",
          position: "relative",
          left: "-1.5rem",
          borderRadius: "0 0 0.5rem 0",
        }
      }
    },
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          maxWidth: '1745px',
          [styling.breakpoints.between("mobile","tablet")]: {
            maxWidth: '90%',
          },
          [styling.breakpoints.between("tablet","laptop")]: {
            maxWidth: '665px',
          },
          [styling.breakpoints.between("laptop","desktop")]: {
            maxWidth: '1280px',
          },
          [styling.breakpoints.between("desktop","qhd")]: {
            maxWidth: '1365px',
          },
          [styling.breakpoints.between("qhd","uhd")]: {
            maxWidth: '1545px',
          },
          [styling.breakpoints.up("uhd")]: {
            maxWidth: '1745px',
          }
        },
      }
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          lineHeight: "1",
          [styling.breakpoints.only("mobile")]: {
            fontSize: "1rem",
          },
          [styling.breakpoints.only("tablet")]: {
            fontSize: "1.55rem",
          },
          [styling.breakpoints.only("laptop")]: {
            fontSize: "1.09rem",
          },
          [styling.breakpoints.only("desktop")]: {
            fontSize: "1.19rem",
          },
          [styling.breakpoints.only("qhd")]: {
            fontSize: "1.38rem",
          },
          [styling.breakpoints.only("uhd")]: {
            fontSize: "1.58rem",
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#db2828",
          textDecoration: "none",
          '&:hover': {
            color: "#f16a6a",
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "rgba(0,0,0,0.67)",
          '&:hover': {
            backgroundColor: "rgba(0,0,0,0.1)",
          },
          '&.Mui-checked': {
            color: "#ff695e",
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          label: {
            color: "rgba(0,0,0,0.5)",
            padding: "0rem 0.25rem",
            "&.Mui-focused": {
              color: "#ff695e",
            }
          },
          input: {
            color: "rgba(0,0,0,0,87)",
            padding: "1rem 1.5rem",
          },
          "& .MuiInputBase-root": {
            fieldset: {
              border: "2px solid rgba(0,0,0,0.23)",
            },
            "&.MuiOutlinedInput-root": {
              backgroundColor: "rgba(225,225,225,0.9)",
            },
            "& .MuiOutlinedInput-input": {
              backgroundColor: "rgba(225,225,225,0.9)",
            },
            "&.MuiFilledInput-root": {
              backgroundColor: "rgba(225,225,225,0.9)",
              padding: "1rem 0 0",
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ff695e",
            },
            "&.MuiInput-root::before": {
              borderBottom: "2px solid rgba(0,0,0,0.23)",
            },
            "&.MuiFilledInput-root::before": {
              borderBottom: "2px solid rgba(0,0,0,0.23)",
            },
            "&.MuiInput-root::after": {
              borderColor: "#ff695e",
            },
            "&.MuiFilledInput-root::after": {
              borderColor: "#ff695e",
            },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(0,0,0,0.67)",
            },
          },
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "1rem !important",
          fontWeight: 400,
          margin: "1rem 0 0 0.5rem",
        }
      }
    }
  }
});

export default styling;