import React from 'react';
import { Icon, ListItem, ListItemIcon, ListItemText } from '@mui/material';

const MUIListItem = ({ content }) => {
  const {
    listItemIcon,
    divider,
    dense,
    disableGutters,
    disablePadding,
    listItemTextPrimary,
    primaryVariant,
    primaryComponent,
    listItemTextSecondary,
    secondaryVariant,
    secondaryComponent,
  } = content;

  const getIconComponent = () => {
    if (listItemIcon.startsWith('Md')) {
      const iconName = listItemIcon.replace('Md', '').replace(/([A-Z])/g, '_$1').trim().substring(1).toLowerCase();
      const iconClass = listItemIcon.includes('Outline') ? 'material-icons-outlined' : 'material-icons';
      return <Icon baseClassName={iconClass}>{iconName}</Icon>;
    } else if (listItemIcon.startsWith('Fa')) {
      const iconName = listItemIcon.replace(/([A-Z])/g, '-$1').trim().substring(1).toLowerCase();
      return <Icon baseClassName={`fa ${iconName}`} />;
    }
    return null;  // Return null if no icon matches the criteria
  };

  return (
    <ListItem
      divider={divider}
      dense={dense}
      disableGutters={disableGutters}
      disablePadding={disablePadding}
    >
      <ListItemIcon sx={{ minWidth: '5%' }}>
        {getIconComponent()}
      </ListItemIcon>
      <ListItemText
        primary={listItemTextPrimary}
        primaryTypographyProps={{ variant: primaryVariant, component: primaryComponent }}
        secondary={listItemTextSecondary}
        secondaryTypographyProps={{ variant: secondaryVariant, component: secondaryComponent }}
      />
    </ListItem>
  );
};

export default MUIListItem;