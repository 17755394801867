import React from 'react';
import { List } from '@mui/material';

import MUI from './Mui';

const MUIList = ({ content }) => (
  <>
    {content.lists.data.map((list, index) => (
      <List
        sx={{ width: '100%' }}
        key={`list-${index}`} // Ensuring unique keys for each list
      >
        {list.attributes.items.map((mui, itemIndex) => (
          <MUI
            type={mui.__component}
            content={mui}
            key={`item-${index}-${itemIndex}`} // Ensuring unique keys for each list item within its parent list
          />
        ))}
      </List>
    ))}
  </>
);

export default MUIList;