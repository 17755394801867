import React from 'react';
import MuiMarkdown from 'mui-markdown';
import { Typography } from '@mui/material';

const MUIRichText = ({ content }) => (
  <Typography component="div" className={content.className}>
    <MuiMarkdown>
      {content.content}
    </MuiMarkdown>
  </Typography>
);

export default MUIRichText;