import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useFetchData from '../Hooks/useFetchData';
import Notifications from '../Components/Notifications';

import Banner from '../Components/Banner';
import Box from '../Components/Boxes';
import SEO from '../Components/SEO';

const Page = ({ pagePath, params }) => {

  const fetchParams = useMemo(() => ({
    'filters[path]': pagePath,
    'populate': 'deep,8'
  }), [pagePath]);

  const { loading, data, error } = useFetchData('pages', fetchParams);

  if (loading) return <Notifications loading={loading} />
  if (error) return <Notifications error={`API ERROR: /api/pages - ${error.status} ${error.message} - User may need to enter required page information into the CMS...`} />;

  const { seo, alerts, banner, content, subfooter, footer } = data[0]?.attributes || {};

  return(
    <>
      <SEO seo={seo} />

      {alerts?.data.length > 0 && alerts.data.map(alert => (
        <Box 
          className={`Alert ${alert.attributes.className || ''}`}
          content={alert}
          key={uuidv4()}
        />
      ))}

      {banner?.data && (
        <Banner 
          className={`Banner ${banner.className || ''}`}
          content={banner}
        />
      )}

      {content?.data && content.data.map(box => (
        <Box
          className={`Section ${box.attributes.className || ''}`}
          content={box}
          params={params}
          key={uuidv4()}
        />
      ))}

      {subfooter?.data && subfooter.data.map(subfooter => (
        <Box
          className={`SubFooter ${subfooter.attributes.className || ''}`}
          content={subfooter}
          key={uuidv4()}
        />
      ))}

      {footer?.data.length > 0 && footer.data.map(footer => (
        <Box
          className={`Footer + ${footer.attributes.className || ''}`}
          content={footer}
          key={uuidv4()}
        />
      ))}
    </>
  );
}

export default Page;