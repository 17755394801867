import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Carousel from 'react-material-ui-carousel';
import MuiMarkdown from 'mui-markdown';
import { Typography } from '@mui/material';

const MUISlide = ({ content }) => {
  return(
    <Carousel navButtonsAlwaysInvisible={true} indicatorContainerProps={{
      style: {
          marginTop: '1rem',
          position: 'relative',
      },
  }}>
      {content.slides.map((slide) => {
        return(
          <Typography component="div" key={uuidv4()}>
            <MuiMarkdown>
              {slide.content}
            </MuiMarkdown>
          </Typography>
        );
      })}
    </Carousel>
  )
};

export default MUISlide;