import React, { useState, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import useFetchData from '../src/Hooks/useFetchData';
import Notifications from '../src/Components/Notifications';

import Theme from '../src/Theme/Theme';
import Page from '../src/Pages/Page';

const App = () => {
  const [errorMessage, setErrorMessage] = useState(null);

  const queryParameters = useMemo(() => ({ 'populate': '*'}), []);
  const { loading, data, error } = useFetchData('pages', queryParameters);

  if(error && !errorMessage) setErrorMessage(error.message);

  const PageWrapper = React.memo(() => {
    const params = useParams();

    let pagePath = 'landingpage';
    if(params.page) pagePath = data.find(p => p.attributes.path === params.page)?.attributes.path || '404';

    return <Page pagePath={pagePath} params={params} />;
  })

  if (loading) return <Notifications loading={loading} />
  if (errorMessage) return <Notifications error={errorMessage} />

  return (
    <Theme>
      <Router>
        <Routes>
          <Route path="/" element={<PageWrapper />} />
          <Route path="/:page" element={<PageWrapper />} />
          <Route path="/:page/post/:id" element={<PageWrapper />} />
          <Route path="/:page/category/:catid" element={<PageWrapper />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </Theme>
  );
};

export default App;